html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif; font-size: 16px;
  color: #454545;
  -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
  }

.diagonal-box {
  position: relative;
  height: 100%;
  width: 100%;
}

.diagonal-box:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #654ea3, #eaafc8);
  transform: skewY(-11deg);
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
 }