.xterm {
  /*font-family: ${fontFamily};*/
  font-feature-settings: 'liga' 0;
  position: relative;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.xterm.focus,
.xterm:focus {
  outline: none;
}

.xterm .xterm-helpers {
  position: absolute;
  top: 0;
  /**
  * The z-index of the helpers must be higher than the canvases in order for
  * IMEs to appear on top.
  */
  z-index: 10;
}

.xterm .xterm-helper-textarea {
  /*
  * HACK: to fix IE's blinking cursor
  * Move textarea out of the screen to the far left, so that the cursor is not visible.
  */
  position: absolute;
  opacity: 0;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  z-index: -10;
  /** Prevent wrapping so the IME appears against the textarea at the correct position */
  white-space: nowrap;
  overflow: hidden;
  resize: none;
}

.xterm .composition-view {
  /* TODO: Composition position got messed up somewhere */
  /* background: ${backgroundColor};
  color: ${foregroundColor}; */
  display: none;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
}
.xterm .composition-view.active {
  display: block;
}
.xterm .xterm-viewport {
  /* On OS X this is required in order for the scroll bar to appear fully opaque */
  /* background-color: ${backgroundColor}; */
  overflow-y: scroll;
  cursor: default;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
.xterm .xterm-screen {
  position: relative;
}
.xterm canvas {
  position: absolute;
  left: 0;
  top: 0;
}
.xterm .xterm-scroll-area {
  visibility: hidden;
}
.xterm .xterm-char-measure-element {
  display: inline-block;
  visibility: hidden;
  position: absolute;
  left: -9999em;
}
.xterm {
  cursor: text;
}
.xterm.enable-mouse-events {
  /* When mouse events are enabled (eg. tmux), revert to the standard pointer cursor */
  cursor: default;
}
.xterm.xterm-cursor-pointer {
  cursor: pointer;
}
.xterm.xterm-cursor-crosshair {
  /* Column selection mode */
  cursor: crosshair;
}
.xterm .xterm-accessibility,
.xterm .xterm-message {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  color: transparent;
}
.xterm .xterm-accessibility-tree:focus [id^='xterm-active-item-'] {
  outline: 1px solid #f80;
}
.xterm .live-region {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}


.terminal ::-webkit-scrollbar {
  width: 5px;
}

.terminal ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(131,58,180,1) 0%, rgba(252,176,69,1) 100%);
}

.terminal ::-webkit-scrollbar-thumb:window-inactive {
  background: linear-gradient(180deg, rgba(131,58,180,1) 0%, rgba(252,176,69,1) 100%);
}