.events li.event-item { 
  display     : flex; 
}

.events time { 
  position : relative;
  color    : #ccc;
  padding  : 0 0.75em;
}

.events time::after { 
   content: "";
   position      : absolute;
   z-index       : 2;
   right         : 0;
   top           : 0;
   transform     : translateX(50%);
   border-radius : 50%;
   background    : #fff;
   border        : 1px #ccc solid;
   width         : .8em;
   height        : .8em;
}


.events li>div {
  padding  : 0 1.25em 1.25em 1.25em;
  position : relative;
}

.events li>div::before {
   content     : "";
   position    : absolute;
   z-index     : 1;
   left        : 0;
   height      : 100%;
   border-left : 1px #ccc solid;
}

.events strong {
   display: block;
}